.btn {
    border-radius: 5px;
    padding: 10px 25px;
    margin-bottom: 20px;
    transition: all 0.4s;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    z-index: 0;

    @include directify($directions) {
        #{directed('margin-right')}: 15px;
    }

    &:last-child {
        margin-right: 0;

        @include directify($directions) {
            #{directed('margin-right')}: 0;
        }
    }

    &:before {
        position: absolute;
        height: 0;
        width: 0;
        border-radius: 50%;
        background-color: $color-accent;
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
        z-index: -1;
        content: '';
        top: 0;

        @include directify($directions) {
            transform: #{directed('translate')};
        }

        @include directify($directions) {
            #{directed('left')}: 0px;
        }
    }

    p {
        display: flex;
        transition: all 0.3s;
        font-weight: 500;
    }

    svg {
        height: 14px;
        width: 14px;
        margin-top: 2px;
        margin-bottom: 0;
        transition: all 0.3s;

        @include directify($directions) {
            #{directed('margin-right')}: 5px;
        }

        @include directify($directions) {
            #{directed('margin-left')}: 0px;
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        outline: none;
        box-shadow: none !important;

        &:before {
            height: 500%;
            width: 225%;
        }
    }

    &:focus,
    &:active,
    &:active:focus {
        &:before {
            transition: all 0s;
        }
    }

    &.square {
        border-radius: 0;
    }

    &.rounded {
        border-radius: 30px !important;
    }

    &.icon {
        padding: 10px 15px;

        &:hover {
            p {
                @include themify($themes) {
                    color: themed('colorText');
                }
            }

            svg {
                @include themify($themes) {
                    fill: themed('colorText');
                }
            }
        }

        &.icon--right {
            svg {
                margin-top: 2px;
                margin-bottom: 0;

                @include directify($directions) {
                    #{directed('margin-right')}: 0px;
                    #{directed('margin-left')}: 5px;
                }
            }
        }
    }

    &.btn-sm {
        padding: 5px 25px;
        font-size: 14px;
    }

    &.btn-lg {
        padding: 12px 25px;
        font-size: 14px;
    }

    &.btn-secondary {
        background-color: $color-light-gray;
        border-color: $color-light-gray;
        color: $color-gray;

        &:before {
            background-color: darken($color-light-gray, 10%);
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            border-color: darken($color-light-gray, 10%);
            color: $color-gray;
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active {
            background-color: $color-dusty-white;
            border-color: $color-dusty-white;
        }
    }

    &.btn-outline-secondary {
        border-color: $color-light-gray;

        &,
        p {
            @include themify($themes) {
                color: themed('colorText');
            }
        }

        svg {
            @include themify($themes) {
                fill: themed('colorText');
            }
        }

        &:before {
            background-color: $color-light-gray;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            background: transparent;
            border-color: $color-light-gray;
            color: $color-gray;

            p {
                color: $color-gray;
            }

            svg {
                fill: $color-gray;
            }
        }
    }

    &.btn-primary {
        background-color: $color-blue;
        border-color: $color-blue;

        &:before {
            background-color: $color-blue-hover;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            border-color: $color-blue-hover;
        }
    }

    &.btn-outline-primary {
        color: $color-blue;
        border-color: $color-blue;

        p {
            color: $color-blue;
        }

        svg {
            fill: $color-blue;
        }

        &:before {
            background-color: $color-blue;
        }
    }

    &.btn-success {
        background-color: $color-btn-green;
        border-color: $color-btn-green;

        &:before {
            background-color: $color-btn-green-hover;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            border-color: $color-btn-green-hover;
        }
    }

    &.btn-outline-success {
        color: $color-accent;
        border-color: $color-accent;

        p {
            color: $color-accent;
        }

        svg {
            fill: $color-accent;
        }

        &:before {
            background-color: $color-accent;
        }
    }

    &.btn-warning {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-white;

        &:before {
            background-color: $color-yellow-hover;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            border-color: $color-yellow-hover;
        }
    }

    &.btn-outline-warning {
        color: $color-yellow;
        border-color: $color-yellow;

        p {
            color: $color-yellow;
        }

        svg {
            fill: $color-yellow;
        }

        &:before {
            background-color: $color-yellow;
        }
    }

    &.btn-danger {
        background-color: $color-red;
        border-color: $color-red;

        &:before {
            background-color: $color-red-hover;
        }

        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            border-color: $color-red-hover;
        }
    }

    &.btn-outline-danger {
        color: $color-red;
        border-color: $color-red;

        p {
            color: $color-red;
        }

        svg {
            fill: $color-red;
        }

        &:before {
            background-color: $color-red;
        }
    }

    &,
    &.btn-primary,
    &.btn-danger,
    &.btn-warning,
    &.btn-success,
    &.btn-outline-secondary,
    &.btn-secondary,
    &.btn-outline-primary,
    &.btn-outline-danger,
    &.btn-outline-warning,
    &.btn-outline-success,
    &.icon,
    &.icon.btn-secondary {
        &.disabled {
            // background-color: $color-background-body;
            // border-color: $color-background-body;
            // color: $color-dusty-white;
            pointer-events: none;

            p {
                color: $color-dusty-white;
            }

            svg {
                fill: $color-dusty-white;
            }
        }
    }

    &.btn-primary,
    &.btn-danger,
    &.btn-warning,
    &.btn-success {
        p {
            color: $color-white;
        }

        svg {
            fill: $color-white;
        }
    }

    &.btn-outline-primary,
    &.btn-outline-danger,
    &.btn-outline-warning,
    &.btn-outline-success {
        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            color: $color-white;
            background: transparent;
        }
    }

    &.btn-primary,
    &.btn-danger,
    &.btn-warning,
    &.btn-success,
    &.btn-outline-primary,
    &.btn-outline-danger,
    &.btn-outline-warning,
    &.btn-outline-success {
        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            p {
                color: $color-white;
            }

            svg {
                fill: $color-white;
            }
        }
    }

    &.expand {
        svg {
            width: 0;
            transition: all 0.3s;
        }

        &.expand--load {
            @keyframes rotating {
                from {
                    transform: rotate(0deg);
                }

                to {
                    transform: rotate(360deg);
                }
            }

            svg {
                width: 14px;
                animation: rotating 2s linear infinite;
            }
        }
    }
}

.btn-toolbar {
    margin-top: 15px;
    margin-bottom: 10px;

    & > * {
        @include directify($directions) {
            #{directed('margin-right')}: 15px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: -10px;
    }

    &.btn-toolbar--center {
        & > * {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.btn-group {
    border-radius: 5px;
    margin-bottom: -10px;

    .btn {
        padding: 10px 15px;
        font-weight: 500;

        @include directify($directions) {
            #{directed('margin-right')}: 0px;
        }
    }

    &.btn-group--justified {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .btn {
            width: 100%;
        }
    }

    &.btn-group--icons {
        .btn {
            padding: 7px 8px;
            line-height: 14px;
        }
    }

    &.open .dropdown-toggle {
        box-shadow: none;
    }
}

button:focus,
button:active {
    outline: none;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:focus,
.btn-default.focus,
.btn-default:focus {
    @include themify($themes) {
        background-color: themed('colorBackground');
    }
}

.customizer {
    position: fixed;
    top: 0;
    z-index: 102;

    @include directify($directions) {
        #{directed('right')}: 0px;
    }

    .customizer__toggle {
        span {
            @include directify($directions) {
                #{directed('margin-right')}: 10px;
                #{directed('margin-left')}: 0px;
            }

            @include themify($themes) {
                color: themed('colorText');
            }
        }
    }
}

.btn__wrapper--buy-now {
    display: none;
    position: fixed;
    bottom: 30px;
    z-index: 102;

    @include directify($directions) {
        #{directed('right')}: 10px;
    }

    @media screen and (min-width: 576px) {
        display: inherit;
        bottom: 50px;

        @include directify($directions) {
            #{directed('right')}: 25px;
        }
    }

    @media screen and (min-width: 768px) {
        bottom: 80px;

        @include directify($directions) {
            #{directed('right')}: 55px;
        }
    }
}
