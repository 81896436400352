.topbar {
    position: fixed;
    display: flex;
    width: 100%;
    top: 0;
    min-height: 60px;
    z-index: 101;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    @include themify($themes) {
        background: themed('colorBackground');
    }
}

.topbar__button {
    width: 60px;
    height: 60px;
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:focus {
        outline: none;
    }

    &:hover {
        @include themify($themes) {
            background: themed('colorHover');
        }
    }
}

.topbar__button-icon {
    margin: auto;
    transition: all 0.3s;
    width: 16px;
    z-index: 101;
}

.topbar__logo {
    width: 150px;
    height: 32px;
    margin: auto 0;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;

    @include themify($themes) {
        background-image: themed('logoImg');
    }

    @media screen and (min-width: 835px) {
        display: block;
    }
    @media screen and (min-width: 300px) {
        display: block;
    }
}

.topbar__right {
    position: absolute;
    display: flex;
    height: 100%;

    @include directify($directions) {
        #{directed('right')}: 0px;
        #{directed('margin-right')}: 15px;
    }

    @media screen and (max-width: 835px) {
        @include directify($directions) {
            #{directed('margin-right')}: 0px;
        }
    }
}

.topbar__right-search {
    margin: auto 15px;
}

.topbar__right-over {
    display: flex;
    flex: auto;
    justify-content: space-between;
}

.topbar__left {
    position: absolute;
    display: flex;
    height: 100%;

    @include directify($directions) {
        #{directed('left')}: 0px;
    }
}

.topbar__avatar {
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    padding: 0 15px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        @include themify($themes) {
            background-color: themed('colorHover');
        }
    }

    &:focus {
        outline: none;
    }

    &:before {
        display: none;
    }
}

.topbar__avatar-img,
.topbar__avatar-name,
.topbar__icon {
    margin: auto 0;
}

.topbar__avatar-img {
    
    border-radius: 50%;
    height: 36px;
    width: 36px;
}

.topbar__avatar-name {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    display: none;

    @include directify($directions) {
        #{directed('margin-left')}: 10px;
    }

    @include themify($themes) {
        color: themed('colorText');
    }
}

.topbar__icon {
    height: 18px;
    margin-top: auto;
    fill: lighten($color-gray, 25%);

    @include directify($directions) {
        #{directed('margin-left')}: 8px;
    }
}

.topbar__menu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;

    @include themify($themes) {
        background: themed('colorBackground');
    }

    button {
        padding: 0;

        &:hover {
            @include themify($themes) {
                background-color: themed('colorHover');
            }
        }

        &.topbar__link {
            background-color: transparent;
            border: none;
            padding: 9px 20px;
        }
    }

    *:focus {
        outline: none;
    }
}

.topbar__menu-wrap {
    z-index: 101;
    position: absolute;
    width: 100%;
    min-width: 150px;

    @include directify($directions) {
        #{directed('right')}: 0px;
    }
}

.topbar__link {
    display: flex;
    padding: 9px 20px;
    padding-right: 49px;
    transition: all 0.3s;
    height: 32px;
    width: 100%;
    position: relative;
    cursor: pointer;

    @include themify($themes) {
        color: themed('colorText');
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 2px;
        background: $color-accent;
        opacity: 0;
        transition: all 0.3s;

        @include directify($directions) {
            #{directed('left')}: 0px;
        }
    }

    &:hover {
        @include themify($themes) {
            background-color: themed('colorHover');
        }

        &:before {
            opacity: 1;
        }
    }
}

.topbar__link-title {
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
}

.topbar__link-icon {
    font-size: 13px;
    line-height: 13px;

    @include directify($directions) {
        #{directed('margin-right')}: 10px;
    }

    @include themify($themes) {
        color: themed('colorIcon');
    }
}

.topbar__menu-divider {
    margin: 15px 0;

    @include themify($themes) {
        border-top: 1px solid themed('colorBorder');
    }
}

.topbar__profile {
    position: relative;
    margin-bottom: 0;

    @include directify($directions) {
        #{directed('margin-left')}: 0px;
    }
}

.topbar__collapse {
    position: relative;
    display: block;

    &.topbar__collapse--language {
        min-width: 70px;
        display: block;

        & > button {
            padding: 0 4px;
            width: 100%;
        }
    }
    &:first-child {
        margin-left: 15px;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    @media screen and (min-width: 568px) {
        display: block;
    }
}

.topbar__collapse-content {
    width: 270px;
    position: absolute;
    box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
    z-index: 102;

    @include directify($directions) {
        #{directed('right')}: 0px;
    }

    @include themify($themes) {
        background: themed('colorBackground');
    }

    @media screen and (max-width: 1024px) {
        @include directify($directions) {
            #{directed('right')}: -150px;
        }
    }

    @media screen and (min-width: 520px) {
        width: 330px;
    }

    &.topbar__collapse-content--language {
        max-width: 75px;
        width: 100%;

        @media screen and (max-width: 1024px) {
            @include directify($directions) {
                #{directed('left')}: 0%;
            }
        }
    }
}

.topbar__language-btn {
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    transition: 0.3s;
    padding: 4px 5px 4px 15px;

    &:first-child {
        padding-top: 14px;
    }

    &:last-child {
        padding-bottom: 14px;
    }

    @include directify($directions) {
        text-align: directed('left');
    }

    &:hover {
        color: $color-accent;
    }
}

.topbar__language-btn-title {
    display: flex;
    font-size: 11px;
    align-items: center;
    margin: auto 0;

    @include themify($themes) {
        color: themed('colorText');
    }

    &:not(:last-child) {
        @include directify($directions) {
            #{directed('margin-right')}: 5px;
        }
    }

    img {
        height: 11px;
        width: 16px;

        @include directify($directions) {
            #{directed('margin-right')}: 4px;
        }
    }
}

.topbar__back {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: transparent;
    border: none;
}

.topbar__collapse-title-wrap {
    padding: 20px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include themify($themes) {
        border-bottom: 1px solid themed('colorBorder');
    }
}

.topbar__collapse-item {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    position: relative;
    height: 62px;
    align-items: center;
    flex-wrap: wrap;

    @include directify($directions) {
        #{directed('padding-left')}: 70px;
        #{directed('padding-right')}: 55px;
    }

    @include themify($themes) {
        border-bottom: 1px solid themed('colorBorder');
    }
}

.topbar__collapse-img-wrap {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;

    @include directify($directions) {
        #{directed('left')}: 15px;
    }
}

.topbar__collapse-message {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;

    @include directify($directions) {
        text-align: directed('left');
    }

    &.topbar__collapse-message--mail {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.topbar__collapse-name {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $color-accent;
}

.topbar__collapse-date {
    position: absolute;
    top: 12px;
    font-size: 10px;
    color: $color-gray;
    margin-top: 2px;

    @include directify($directions) {
        #{directed('right')}: 15px;
    }
}

.topbar__collapse-link {
    display: block;
    padding: 10px;
    text-transform: uppercase;
    color: $color-accent;
    transition: 0.3s;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    &:hover {
        color: $color-accent-hover;
    }
}

.topbar__collapse-title {
    font-size: 14px;
    line-height: 16px;
}

.topbar__collapse-button {
    color: lighten($color-gray, 25%);
    border: none;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    transition: 0.3s;
    background: transparent;

    &:hover {
        color: $color-accent;
    }

    @include directify($directions) {
        text-align: directed('right');
    }
}

.topbar__btn {
    font-size: 18px;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background: transparent;
    transition: all 0.3s;

    &:hover {
        @include themify($themes) {
            background-color: themed('colorHover');
        }
    }

    svg {
        margin: auto;
        height: 18px;
        width: 18px;
        fill: lighten($color-gray, 25%);
    }

    @include themify($themes) {
        color: themed('colorText');
    }

    @media screen and (max-width: 640px) {
        padding: 0 5px;
    }

    &.topbar__btn--new {
        .topbar__btn-new-label {
            position: absolute;
            top: 20px;

            @include directify($directions) {
                #{directed('right')}: 9px;
            }

            & > div {
                position: relative;

                &:before {
                    background-color: rgba(224, 83, 111, 0.2);
                    content: '';
                    position: absolute;
                    top: 50%;
                    border-radius: 50%;
                    animation: beforePulse 1.5s infinite;

                    @include directify($directions) {
                        #{directed('left')}: 50%;
                    }

                    @include directify($directions) {
                        transform: #{directed('translate')};
                    }
                }

                &:after {
                    height: 7px;
                    width: 7px;
                    background-color: $color-red;
                    content: '';
                    position: absolute;
                    top: 50%;
                    border-radius: 50%;

                    @include directify($directions) {
                        #{directed('left')}: 50%;
                    }

                    @include directify($directions) {
                        transform: #{directed('translate')};
                    }
                }
            }

            @media screen and (max-width: 576px) {
                top: 10px;
            }
        }
    }

    @keyframes beforePulse {
        from {
            width: 7px;
            height: 7px;
        }

        25% {
            width: 13px;
            height: 13px;
        }

        to {
            width: 7px;
            height: 7px;
        }
    }
}

.topbar__nav {
    width: 75%;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;

    @include directify($directions) {
        #{directed('margin-right')}: 140px;
    }
}

.topbar .topbar__nav-dropdown-toggle {
    height: 60px;
    background: transparent;
    border-radius: 0;
    border: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @include themify($themes) {
        color: themed('colorText');
    }

    &:before {
        display: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        @include themify($themes) {
            background-color: themed('colorHover');
        }
    }

    svg {
        height: 16px;
        width: 16px;
        fill: $color-additional;

        @include directify($directions) {
            #{directed('margin-left')}: 3px;
        }

        @include directify($directions) {
            #{directed('margin-right')}: 0px;
        }
    }
}

.topbar__nav-dropdown-menu {
    width: 240px;
    border-top: 2px solid $color-accent;

    button {
        padding: 0;
    }
}

.topbar__link-badge {
    position: relative;
    width: 26px;
    height: 14px;
    background-color: $color-red;
    font-size: 8px;
    font-weight: 400;
    padding: 2px;
    line-height: 9px;
    text-transform: uppercase;
    border-radius: 7px;

    @include directify($directions) {
        #{directed('margin-left')}: 5px;
    }

    span {
        position: absolute;
        top: 3px;
        width: 26px;
        text-align: center;

        @include directify($directions) {
            #{directed('left')}: 0px;
        }
    }
}

.topbar__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 10px 25px;
    transition: 0.3s;
    font-size: 14px;

    &:hover {
        @include themify($themes) {
            background-color: themed('colorHover');
        }
    }

    @include themify($themes) {
        color: themed('colorText');
    }
}

.topbar__category-wrap {
    position: relative;

    &:hover {
        & > .topbar__submenu {
            opacity: 1;
            width: auto;
            height: auto;
        }
    }
}

.topbar__category-icon {
    position: absolute;
    font-size: 10px;
    line-height: 14px;

    @include directify($directions) {
        #{directed('right')}: 20px;
    }

    @include themify($themes) {
        color: themed('colorIcon');
    }
}

.rtl-support {
    .topbar__submenu {
        transform: none;
        right: 100%;
    }

    .topbar__category-icon {
        transform: rotate(180deg);
    }
}

.ltr-support {
    .topbar__submenu {
        right: 1px;
    }
}

.topbar__submenu {
    position: absolute;
    top: 0;
    transform: translateX(100%);
    transition: 0.3s;
    opacity: 0;
    width: 0;
    height: 0;

    @include themify($themes) {
        background-color: themed('colorHover');
    }

    .topbar__link {
        &:hover {
            @include themify($themes) {
                background-color: themed('colorBackground');
            }
        }
    }
}

.topbar__search {
    display: flex;
    margin: auto 0;
    padding: 0;
    position: relative;
}

.topbar__search-field {
    width: 100%;
    height: 26px;
    max-width: 350px;
    min-width: 50px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 13px;
    transition: border 0.3s;

    &::placeholder {
        color: #cac1c1;
        @include directify($directions) {
            #{directed('padding-right')}: 50px;
        }
    }

    @include directify($directions) {
        #{directed('margin-right')}: auto;
        #{directed('margin-left')}: 0px;
    }

    @include directify($directions) {
        #{directed('padding-left')}: 10px;
        #{directed('padding-right')}: 46px;
    }

    @include themify($themes) {
        background-color: themed('colorBackgroundBody');
        border: 1px solid themed('colorBackgroundBody');
        color: themed('colorText');
    }

    &:focus,
    &:active {
        outline: none;
        border: 1px solid $color-accent;
    }
}

.layout--top-navigation {
    .topbar__search-field {
        &::placeholder {
            color: lighten($color-gray, 25%);
        }

        &.topbar__search-field--open {
            width: 55vw;

            @media screen and (max-width: 1200px) {
                width: 59vw;
            }

            @media screen and (max-width: 1049px) {
                width: 20vw;
            }
        }
    }
}

.topbar__search-btn {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 13px;
    border: none;
    padding: 0;
    background: transparent;

    &:hover {
        @include themify($themes) {
            background-color: themed('colorBackgroundBody');
        }
    }

    @include directify($directions) {
        #{directed('right')}: 0px;
    }
}

@media screen and (min-width: 480px) {
    .topbar__avatar-name {
        display: block;
    }

    .topbar__menu {
        width: 100%;

        @include directify($directions) {
            #{directed('left')}: 0px !important;
        }
    }
}

@media screen and (min-width: 576px) {
    .topbar__button {
        &.topbar__button--mobile {
            display: none;
        }
    }

    .topbar.topbar--navigation {
        .topbar__button.topbar__button--mobile {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {
    .topbar__search {
        display: flex;
    }
}

@media screen and (min-width: 1400px) {
    .topbar__nav {
        display: flex;
    }

    .topbar.topbar--navigation {
        .topbar__logo {
            display: block;

            @include directify($directions) {
                #{directed('margin-left')}: 15px;
            }
        }

        .topbar__button.topbar__button--desktop {
            display: none;
        }

        .topbar__button.topbar__button--mobile {
            display: none;
        }

        .topbar__avatar-name {
            display: none;
        }

        .topbar__profile {
            @include directify($directions) {
                #{directed('margin-left')}: 0px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .topbar.topbar--navigation {
        .topbar__avatar-name {
            display: block;
        }
    }
}

@media screen and (min-width: 1920px) {
    .topbar__nav-dropdown-toggle,
    .topbar__nav-link {
        width: 240px;
    }
}

@media screen and (max-width: 1550px) {
    .topbar__nav {
        width: 65%;
    }
}

@media screen and (max-width: 700px) {
    .topbar__search-field {
        max-width: 150px;
    }
}

@media screen and (max-width: 640px) {
    .topbar__right-search {
        margin-right: 0;
    }

    .topbar__search-field {
        max-width: 120px;
    }

    .topbar__btn-new-label {
        right: 5px !important;
    }
}

@media screen and (max-width: 576px) {
    .topbar {
        min-height: 60px;
    }

    .topbar__right {
        height: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
    }

    .topbar__right-search {
        margin: 0;
    }

    .topbar__profile {
        margin: inherit;
    }

    .topbar__collapse {
        &:last-child {
            margin-right: 0;
        }

        &:not(last-child) {
            margin: inherit;
        }
    }

    .topbar__collapse-content {
        width: 250px;

        @include directify($directions) {
            #{directed('right')}: -215px;
        }
    }

    .topbar__search {
        margin-left: 220px;
        margin-bottom: 15px;
    }

    .topbar__search-field {
        max-width: 300px;
        margin-bottom: 10px;
        margin-left: 60px;
    }

    .topbar__button {
        &.topbar__button--desktop {
            display: none;
        }
    }

    .topbar.topbar--navigation {
        &.topbar__button--desktop {
            display: none;
        }
    }
}

@media screen and (max-width: 320px) {
    .topbar__right {
        justify-content: flex-end;
    }

    .topbar__search {
        margin-left: 140px;
    }

    .topbar__profile {
        margin: auto 0;
    }

    .topbar__collapse-content {
        width: 230px;

        @include directify($directions) {
            #{directed('right')}: -210px;
        }
    }

    .topbar__menu-wrap {
        @include directify($directions) {
            #{directed('right')}: -50px;
        }
    }
}
