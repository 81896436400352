$themes: (
    light: (
        colorBackground: white,
        colorBackgroundBody: #f2f4f7,
        inboxButtonBackground: #f2f4f7,
        inboxButtonBackgroundHover: darken(#f2f4f7, 5%),
        colorHeaderDataPicker: #6db0ff,
        colorText: #646777,
        colorTextAdditional: #646777,
        logoImg: url(../../shared/img/logo.png),
        colorHover: #fafbfe,
        colorFolderHover: #f0eeee,
        colorBorder: #eff1f5,
        colorIcon: #dddddd,
        imgInvert: invert(0%),
        colorFieldsBorder: #c7c9cd,
        colorBubble: rgba(242, 244, 247, 0.65),
        colorBubbleActive: rgba(234, 238, 255, 0.6),
        colorScrollbar: #b4bfd0,
        colorFitness: #646777,
        colorEmoji: #232329,
        sidebarColor: aliceblue,
    ),
    dark: (
        colorBackground: #232329,
        colorBackgroundBody: #2a2a31,
        inboxButtonBackground: #2a2a31,
        inboxButtonBackgroundHover: lighten(#2a2a31, 5%),
        colorHeaderDataPicker: #063263,
        colorText: #dddddd,
        colorTextAdditional: #999999,
        logoImg: url(../../shared/img/logo/logo_dark.svg),
        colorHover: #38373f,
        colorFolderHover: #ffffff1a,
        colorBorder: #333246,
        colorIcon: #605f7b,
        imgInvert: invert(100%),
        colorFieldsBorder: #33333a,
        colorBubble: rgba(68, 79, 97, 0.65),
        colorBubbleActive: rgba(92, 104, 156, 0.6),
        colorScrollbar: #606071,
        colorFitness: #ffffff,
        colorEmoji: #ffffff,
        sidebarColor: #232329,
    ),
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

$color-accent: #3e8fc6;
$color-light-accent: #55e5bb;
$color-btn-green: #4ce1b6;
$color-btn-green-hover: darken($color-btn-green, 10%);

$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-dark-red: #721c24;
$color-light-red: #f88e7d;
$color-very-light-red: #f8d7da;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-light-blue: #87c3f7;
$color-blue-hover: darken($color-blue, 10%);

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-dusty-white: #dddddd;
$color-white: #ffffff;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-dark-fields-border: #33333a;
$color-black-background: #232329;
$color-background-body: #f2f4f7;
$color-text: #646777;
$color-hover: #fafbfe;

$directions: (
    ltr: (
        direction: ltr,

        right: right,
        left: left,

        margin-right: 'margin-right',
        margin-left: 'margin-left',

        padding-left: 'padding-left',
        padding-right: 'padding-right',

        border-top-left-radius: 'border-top-left-radius',
        border-bottom-left-radius: 'border-bottom-left-radius',

        border-top-right-radius: 'border-top-right-radius',
        border-bottom-right-radius: 'border-bottom-right-radius',

        border-left: 'border-left',
        border-right: 'border-right',

        translate: 'translate(-50%, -50%);',
        mirrorY: 'scale(1, 1)',

        border-right-color: 'border-right-color',

        transform-sidebar: 'rotate(90deg)',

        flex-flow: row nowrap,
        row: 'row',

        sidebar-close: translateX(0),
        sidebar-no-desktop: translateX(calc(0%)),
        '-': '-',
    ),
    rtl: (
        direction: rtl,

        right: left,
        left: right,

        margin-right: 'margin-left',
        margin-left: 'margin-right',

        padding-left: 'padding-right',
        padding-right: 'padding-left',

        border-top-left-radius: 'border-top-right-radius',
        border-bottom-left-radius: 'border-bottom-right-radius',

        border-top-right-radius: 'border-top-left-radius',
        border-bottom-right-radius: 'border-bottom-left-radius',

        border-left: 'border-right',
        border-right: 'border-left',

        translate: 'translate(50%, -50%);',
        mirrorY: 'scale(1, -1)',

        border-right-color: 'border-left-color',
        transform-sidebar: 'rotate(-90deg)',

        flex-flow: row-reverse nowrap,
        row: 'row-reverse',

        sidebar-close: translateX(0),
        sidebar-no-desktop: translateX(calc(100%)),
        '-': '+',
    ),
);

@mixin directify($directions) {
    @each $direction, $map in $directions {
        .#{$direction}-support & {
            $direction-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($directions, $direction), '#{$key}');
                $direction-map: map-merge(
                    $direction-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $direction-map: null !global;
        }
    }
}

@function directed($key) {
    @return map-get($direction-map, $key);
}

$borders: (
    round-borders-on: (
        borderRadius: 15px,
    ),
    round-borders-off: (
        borderRadius: 5px,
    ),
);

@mixin borderify($borders) {
    @each $border, $map in $borders {
        .#{$border} & {
            $border-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($borders, $border), '#{$key}');
                $border-map: map-merge(
                    $border-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $border-map: null !global;
        }
    }
}

@function bordered($key) {
    @return map-get($border-map, $key);
}

$shadows: (
    blocks-shadows-on: (
        blocksShadows: 0 10px 30px 1px rgba(0, 0, 0, 0.06),
    ),
    blocks-shadows-off: (
        blocksShadows: none,
    ),
);

@mixin shadowify($borders) {
    @each $shadow, $map in $shadows {
        .#{$shadow} & {
            $shadow-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($shadows, $shadow), '#{$key}');
                $shadow-map: map-merge(
                    $shadow-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $shadow-map: null !global;
        }
    }
}

@function shadowed($key) {
    @return map-get($shadow-map, $key);
}
